



















import Vue from 'vue';
import Component from 'vue-class-component';
import { apiStatus } from '@/store';

const IconSync = () => import('common-modules/src/assets/fa-icons/light/sync.svg');
const IconCheck = () => import('common-modules/src/assets/fa-icons/regular/check.svg');

const props = Vue.extend({
  props: {
    initialSyncStatus: String,
    sendMessageStatus: String,
  },
});

@Component({
  components: {
    IconCheck,
    IconSync,
  },
})
export default class LmsContactSync extends props {
  closeOverlay (): void {
    this.$emit('close-overlay');
  }

  get isLoadingData (): boolean {
    return this.initialSyncStatus === apiStatus.STATUS_FETCHING;
  }

  get isSubmittingData (): boolean {
    return this.sendMessageStatus === apiStatus.STATUS_FETCHING;
  }

  get isLoading (): boolean {
    return this.isLoadingData
      || this.isSubmittingData;
  }

  get isSuccess ():boolean {
    return this.sendMessageStatus === apiStatus.STATUS_SUCCESS;
  }

  get rootClasses (): Record<string, boolean> {
    return {
      'lms-contact-sync--loading': this.isLoading,
      'lms-contact-sync--success': this.isSuccess,
    };
  }
}
